export const FunFact = ({ fact }) => {

    return (
        <div className="fun-fact">
            {fact && (
                <div>
                    <h3>{fact.title}</h3>
                    <p>{fact.content}</p>
                </div>
            )}
        </div>
    );
};
