import React, { useState, useEffect } from 'react'
import { FunFact, Spinner } from '../components'
import factStore from '../facts.json'

export const FunFacts = () => {
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const getRandomFact = () => {
        const randomIndex = Math.floor(Math.random() * factStore.facts.length)
        return factStore.facts[randomIndex]
    }

    const [facts, setFacts] = useState(factStore.facts);
    const [currentFact, setCurrentFact] = useState(getRandomFact());
    const [isShuffled, setIsShuffled] = useState(false);

    useEffect(() => {
        if (isShuffled) return

        const factsCopy = [...facts]
        const shuffledFacts = shuffleArray(factsCopy)
        setFacts(shuffledFacts)
        setIsShuffled(true)
    }, [facts, isShuffled])



    const handleClick = () => {
        const factsCopy = [...facts]

        setCurrentFact(factsCopy.pop())
        setFacts(factsCopy)

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    };

    return (
        <>
            <h1>Did you know...</h1>
            {isShuffled ? <FunFact fact={currentFact} /> : <Spinner />}
            <div className="button-container">
                <button onClick={handleClick}>Another fact!</button>
            </div>
        </>
    )
}