import React from 'react'

const EmailLink = ({ emailAddress = 'contact@factyfloof.com', subjectLine = '' }) => {
    const href = `mailto:${emailAddress}?subject=${encodeURIComponent(subjectLine)}`;

    return (
        <a href={href}>
            Send email
        </a>
    );
}

export const About = () => {

    return (
        <div className="about">
            <h1>About</h1>
            <p>
                Facty Floof is a fun, quirky and fascinating website where you can find out fascinating facts. Like to laugh? Looking for something interesting to tell your friends at the water cooler? This is the place!
            </p>

            <h2 style={{ marginBottom: '10px' }}>Contact</h2>
            <div className="email-link-wrapper">
                <EmailLink />
            </div>
        </div>
    )
}