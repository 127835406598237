import React from 'react'
import {ReactComponent as LoadingSpinner} from '../assets/spinner.svg'

export const Spinner = () => {
    return (
        <div className="spinner">
            <LoadingSpinner />
        </div>
    )
}
