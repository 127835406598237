import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ROUTES } from './routes';
import { FunFacts, About } from './pages';
import { Header } from './components'

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Header />
        <div className="container">
          <Routes>
            <Route path={ROUTES.HOME} element={<FunFacts />} />
            <Route path={ROUTES.ABOUT} element={<About />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
